<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['name','operation','ratio']"
            :is_page="false"
            ref="list"
            rowKey="id"
            :submit_preprocessing="submit_preprocessing"
            @expandedRowsChange="expandedRowsChange($event)"
            @list_after="list_after"
        >
			<template slot="right_btn">
				<!-- <export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.export/visitCensus"></export-table> -->
                <a v-if="this.$store.state.page_auth.export" @click="report()">
					<a-button class="a_btn">导出</a-button>
				</a>
            </template>

            <template slot="table_before">
                <div
                    style="text-align:center; padding-bottom:15px"
                >{{chart_name ? chart_name : '总'}}拜访次数趋势</div>
                <div id="container_imitation" style="height:350px; width:100%"></div>
            </template>
            <template slot="ratio" slot-scope="data">
                <span>{{data.text}}%</span>
            </template>
			<template slot="name" slot-scope="data">
			    <span :style="data.record.user_status == -1 ? 'color:#FF2727;' : ''">{{data.record.name}}</span>
			</template>
            <template slot="operation" slot-scope="data">
                <a @click="get_details(data.record)">查看图表</a>
            </template>
        </TableList>
    </div>
</template>

<script>

import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import RightDrawer from "@/components/RightDrawer";
import LookImages from "@/components/LookImages";
import { censusDepartmentV2, censusChartV2 } from "@/api/customer";
import { Chart } from '@antv/g2';
import moment from 'moment';


const columns = [
    {
        title: "名称",
        dataIndex: "name",
		scopedSlots: {
		    customRender: "name"
		}
    },
    {
        title: "团队人数",
        dataIndex: "user_num",
    },
    {
        title: "上班天数",
        dataIndex: "working_num",
    },
    {
        title: "客户数",
        dataIndex: "customer_num",
    },
    {
        title: "个人拜访客户数",
        dataIndex: "visited",
    },
    {
        title: "未拜访客户数",
        dataIndex: "not_visit",
    },
    {
        title: "总拜访客户数",
        dataIndex: "total_visited",
    },
    {
        title: "拜访总次数",
        dataIndex: "visit_num",
    },
    {
        title: "覆盖率",
        dataIndex: "ratio",
        scopedSlots: {
            customRender: "ratio"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

const columns_details = [
    {
        title: '商品',
        dataIndex: 'goods_title'
    },
    {
        title: '价格',
        dataIndex: 'pay_price'
    },
    {
        title: '数量',
        dataIndex: 'num',
        scopedSlots: {
            customRender: "num"
        }
    },
    {
        title: '小计',
        dataIndex: 'sub_price'
    }
]

let times_list = [
    {
        key: 1,
        value: '24小时'
    },
    {
        key: 7,
        value: '最近7天'
    },
    {
        key: 30,
        value: '最近30天'
    }
]

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
        RightDrawer,
        LookImages
    },
    data() {
        return {
            get_table_list: censusDepartmentV2,
            submit_preprocessing: {
                array_to_string: ['department','role']
            },
            details: {
                visit_data: {

                },
            },
            chart: null,
            chart_name: '',
            columns,
            columns_details,
            drawer_visible: false,
            form_data_seo: {
				...this.$config.form_data_seo,
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					// {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "username",
                        title: "人员",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
                    },
					
					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
					},
                    {
						type: 'radio',
						mode:'button',
                        name: 'times',
                        title: '日期',
						options: {
							 initialValue: 1
						},
                        list: times_list
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "日期",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                
            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});
		
		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role") {
        //             item.list = res;
        //         }
        //     });
        // });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role") {
					item.treeData = res;
				}
			});
		});

        this.$nextTick(res => {
            this.get_details();
        })
    },
    mounted() {
        const e = document.createEvent('Event')
        e.initEvent('resize', true, true)
        window.dispatchEvent(e)
    },
    methods: {
        created_chart() {
            const chart = new Chart({
                container: 'container_imitation',
                autoFit: true,
                height: 500,
                theme: 'default'
            });

            let data = [

            ];

            chart.data(data);
            this.chart = chart;
            chart.scale({
                temperature: {
                    nice: true,
                },
            });

            chart.scale('num', {
                alias: '拜访次数',
            });

            chart.tooltip({
                showCrosshairs: true,
                shared: true,
            });

            chart
                .line()
                .position('xaxis*num')
                .color('#1890ff')
                .shape('smooth');

            chart
                .point()
                .position('xaxis*num')
                .color('#1890ff')
                .shape('circle');

            chart.render();
        },
        get_details(item = {}) {
            if (!item.id) {
                item.id = '';
            }

            this.chart_name = item.name;

            let times = this.$refs.list.seo_data.times;
            censusChartV2({
                data: {
                    id: item.id,
                    times: times ? times : 1,
					type: item.type,
					...this.$refs.list.seo_data
                }
            }).then(res => {

                if (!this.chart) {
                    this.created_chart();
                }

                this.chart.data(res.data.list)
                this.chart.render();
            })
        },
        get_list_key(list, key, index_arr = []) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == key) {
                    return list[i]
                }

                if (Array.isArray(list[i].children) && list[i].children.length) {
                    let value = this.get_list_key(list[i].children, key);
                    if (value !== false) {
                        return value;
                    }
                }
            }

            return false;
        },
        expandedRowsChange(e) {
            e.forEach(key => {
                let list = JSON.parse(JSON.stringify(this.$refs.list.list));

                let item = this.get_list_key(list, key);

                if (!item.hasOwnProperty('is_req')) {
                    censusDepartmentV2({
                        data: {
                            id: key,
                            ...this.$refs.list.seo_data
                        }
                    }).then(res => {

                        item.children = [
                            ...res.data.user.map((vo) => {
                                
                                return vo;
                            }),
                            ...res.data.list.map((vo) => {
                                vo.children = [];
                                
                                return vo;
                            })

                        ];
                        item.is_req = true;

                        this.$refs.list.set_data('list', list);
                    })
                }
            });
        },
        list_after(res) {
            this.get_details();
            let list = this.$refs.list.list;
            list = list.map(item => {
                item.children = [];
                
                return item;
            });

        },
        show() {
            this.drawer_visible = true;
        },
        drawer_visible_close() {
            this.drawer_visible = false;
        },

        report() {
            let seoData = this.$refs.list.seo_data
            if(seoData.times > 0 && !seoData.start_time && !seoData.end_time){
                let times = seoData.times == 1 ? seoData.times : seoData.times - 1
                let startDates = moment(new Date(new Date().getTime() - (times * 86400000))).format('YYYY-MM-DD')
                let endDates = moment(new Date()).format('YYYY-MM-DD')
                seoData.start_time = startDates
                seoData.end_time = endDates
            }

            let url_query = {
                department:seoData.department,
                role_id:seoData.role,
                user_status:seoData.user_status,
                username:seoData.username,
                start_time:seoData.start_time,
                end_time:seoData.end_time,
            }

			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_visit_user",
					...url_query
				}
			});
		},

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.visit_img {
    width: 60px;
    height: 60px;
}
</style>